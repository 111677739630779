import { FC } from 'react';

import { VacancyCountBlock } from 'src/components/VacancyCountBlock';
import { getJobFunctionsCountersData } from '../../utils/vacancies-counters';

export type VacanciesAtCityProps = {
  facets?: Record<string, number>;
  pageSlug: string;
  cityName: string;
};

export const VacanciesAtCity: FC<VacanciesAtCityProps> = ({ cityName, facets, pageSlug }) => {
  if (!facets) {
    return null;
  }

  const countersData = getJobFunctionsCountersData(facets);

  const countBlockItems = countersData.map(({ href, ...rest }) => ({
    ...rest,
    href: `/${pageSlug}/${href}`,
  }));

  return <VacancyCountBlock title={`Leukste bijbanen in ${cityName}`} items={countBlockItems} />;
};
