import { FC, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { APP_ROUTES } from 'constants/routes';
import { usePopRouterEventNotShallow } from 'features/search/hooks/usePopRouterEventNotShallow';
import Layout from 'features/layout/templates/Layout';
import { ContentWrapper, MetaTags } from 'src/common';
import { CarouselFeed } from 'src/components/CarouselFeed';
import { VacancyMiniCard } from 'src/components/Cards';
import { SearchResultsSection } from 'src/components/SearchResultsSection';
import { ReadyToJobCta } from 'src/components/JobCta/ReadyToJobCta';
import { ReadyToJobCtaLite } from 'src/components/JobCta/ReadyToJobCtaLite';
import { Summary } from 'src/components/Summary';
import { getBodyComponents } from 'src/components/Strapi';
import { JobsSearchStore } from 'features/search/models/jobsSearch';
import { Job } from 'api_entities/vacancies/types';
import { City } from 'api_entities/cities/types';
import { FacetedResponse } from 'api_entities/types';
import { VacanciesResultPage } from 'api_entities/strapi/types';

import { StyledBodyBreadcrumbs } from 'src/components/Breadcrumbs';
import { useBreadcrumbs } from 'src/components/Breadcrumbs/hooks';
import { Breadcrumb } from 'src/components/Breadcrumbs/types';
import { VacanciesAtCity } from './components/VacanciesAtCity';

import {
  ArticleBodyWrapper,
  ArticleWrapper,
  AsideWrapper,
  Content,
  CitiesBannerContent,
  CitiesBannerWrapper,
} from './VacanciesByCity.styles';
import { pageConfig } from '../Vacancies/page-config';
import { formatLocalDate } from '../../../utils/helpers';

const isProd = process.env.NEXT_PUBLIC_APP_CONTEXT === 'production';

export type VacanciesByCityProps = {
  city: City;
  data: JobsSearchStore['data'];
  filters: JobsSearchStore['filters'];
  pageSlug: string;
  strapiContent?: VacanciesResultPage;
  latestJobs?: FacetedResponse<Job>;
};

const { useStore, filters: jobFilters } = pageConfig['city'];

const VacanciesByCity: FC<VacanciesByCityProps> = observer(
  ({ city, pageSlug, data, filters, latestJobs, strapiContent }) => {
    usePopRouterEventNotShallow();
    const customBreadcrumbs: Breadcrumb[] = useMemo(
      () => [{ href: `/${pageSlug}`, label: city.title }],
      [city.title, pageSlug]
    );
    const breadcrumbs = useBreadcrumbs(customBreadcrumbs);
    const store = useStore(data, filters);

    const { attributes } = strapiContent || {};

    const defaultMetaTitle = `Studenten Bijbaan ${city?.title} - Vacatures ${
      city?.title
    } - ${formatLocalDate(new Date(), 'dd MMMM uuuu')} | Studenten.nl`;
    const defaultMetaDescription = `Zoek jij als student een leuke bijbaan in ${city?.title}? Bekijk hier alle vacatures in ${city?.title}`;

    const transformQuery = useCallback(
      (query?: Record<string, string | string[] | undefined>) => {
        if (query) {
          delete query.slug;

          if (city) {
            query.city = city.title;
          }
        }
      },
      [city?.title]
    );

    const renderBanner = () => (
      <CitiesBannerWrapper>
        <ContentWrapper>
          <CitiesBannerContent>
            <h2>{`De Leukste Studenten Bijbanen in ${city.title}`}</h2>
          </CitiesBannerContent>
        </ContentWrapper>
      </CitiesBannerWrapper>
    );

    return (
      <Layout>
        <MetaTags
          canonicalUrl={isProd}
          title={attributes?.seoMeta?.title || defaultMetaTitle}
          description={attributes?.seoMeta?.description || defaultMetaDescription}
          ldJsonScheme={attributes?.seoMeta?.scheme}
          preventIndexing={attributes?.seoMeta?.preventIndexing}
          keywords={attributes?.seoMeta?.keywords}
        />

        <SearchResultsSection
          store={store}
          filters={jobFilters}
          mainTextContent={attributes?.mainContent}
          renderBanner={renderBanner}
          transformQuery={transformQuery}
          breadcrumbs={breadcrumbs ? <StyledBodyBreadcrumbs items={breadcrumbs} /> : undefined}
        />

        {attributes?.body?.length ? (
          <ContentWrapper as="section">
            <Content>
              <ArticleWrapper>
                <div>
                  {attributes?.summary?.length ? (
                    <Summary title="Samenvatting" items={attributes.summary} />
                  ) : null}
                </div>
                <div>
                  <ArticleBodyWrapper>{getBodyComponents(attributes.body)}</ArticleBodyWrapper>
                </div>
              </ArticleWrapper>
              <AsideWrapper>
                {attributes?.asideTopBaner ? (
                  <ReadyToJobCtaLite
                    title={attributes?.asideTopBaner?.title}
                    subtitle={attributes?.asideTopBaner?.description || ''}
                    link={attributes?.asideTopBaner?.link}
                    shareText={attributes?.asideTopBaner?.shareText}
                  />
                ) : null}
                <ReadyToJobCta
                  title={attributes?.asideBottomBaner?.title || 'Wil jij die leuke bijbaan?'}
                  subtitle={
                    attributes?.asideBottomBaner?.description ||
                    'Ben jij er klaar voor om te starten bij die leuke bijbaan? Schrijf je dan vandaag in.'
                  }
                  link={
                    attributes?.asideBottomBaner?.link || {
                      label: 'Inschrijven',
                      href: APP_ROUTES.LOGIN,
                    }
                  }
                  shareText={attributes?.asideBottomBaner?.shareText || 'Deel deze job'}
                />
              </AsideWrapper>
            </Content>
          </ContentWrapper>
        ) : null}

        {latestJobs ? (
          <VacanciesAtCity
            pageSlug={attributes?.slug || pageSlug}
            cityName={city.title}
            facets={latestJobs.totalFacets.jobFunction}
          />
        ) : null}

        {latestJobs?.data?.length ? (
          <CarouselFeed
            title={`Laatste vacatures in ${city.title}`}
            items={latestJobs.data}
            renderItem={(job: Job) => {
              return (
                <VacancyMiniCard
                  title={job.title}
                  img={job.img}
                  city={job.city}
                  employment={job.employment}
                  company={job?.company?.title}
                  href={`${APP_ROUTES.ALL_VACANCIES}/${job.slug}`}
                />
              );
            }}
          />
        ) : null}
      </Layout>
    );
  }
);

export default VacanciesByCity;
